// extracted by mini-css-extract-plugin
export var resources = "resources-YRj";
export var body = "body-3Mn";
export var resourceList = "resourceList-23W";
export var button = "button-2Cm";
export var link = "link-1D3";
export var active = "active-33s";
export var arrow = "arrow-23w";
export var disabled = "disabled-3Hz";
export var pagination = "pagination-1ja";
export var imageContainer = "imageContainer-2ef";