import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import classNames from "classnames"

import Layout from "@layout"
import Button from "@components/button"
import Link from "@components/link"
import Image from "@components/image"
import SVG from "@components/svg"

import * as styles from "./styles.module.scss"
class ResourceCenterPage extends React.Component {
  // data alias
  page = this.props.data.datoCmsResourcesTruckVan
  pageContext = this.props.pageContext
  resources = this.props.data.allDatoCmsResourceTruckVan.nodes
  totalCount = this.props.data.allDatoCmsResourceTruckVan.totalCount

  render() {
    const bodyContent =
      this.page.bodyNode && this.page.bodyNode.childMarkdownRemark
        ? this.page.bodyNode.childMarkdownRemark.html
        : this.page.body

    const paginationArray = []
    for (let i = 1; i <= this.pageContext.numPages; i++) {
      const link =
        i === 1 ? `/${this.pageContext.permalink}` : `/${this.pageContext.permalink}/${i}`
      paginationArray.push({
        link,
        page: i
      })
    }

    const leftPaginationClasses = classNames(styles.arrow, {
      [styles.disabled]: this.pageContext.currentPage === 1
    })

    const rightPaginationClasses = classNames(styles.arrow, {
      [styles.disabled]: this.pageContext.currentPage === this.pageContext.numPages
    })

    return (
      <>
        <Helmet>
          <html lang="en" />
        </Helmet>

        <Layout
          seoMetaTags={this.page.seoMetaTags}
          menu={this.props.data.menu}
          footer={this.props.data.footer}
          useTriangleBackground={true}
        >
          <div className={styles.resources}>
            <h1>{this.page.title}</h1>
            <div
              className={styles.body}
              dangerouslySetInnerHTML={{
                __html: bodyContent
              }}
            ></div>
            <div className={styles.resourceList}>
              {this.resources &&
                this.resources.map((resource, index) => {
                  const descriptionContent =
                    resource.descriptionNode && resource.descriptionNode.childMarkdownRemark
                      ? resource.descriptionNode.childMarkdownRemark.html
                      : resource.description

                  return (
                    <div key={index}>
                      <div className={styles.imageContainer}>
                        <Image
                          src={{
                            childImageSharp: {
                              gatsbyImageData: {
                                ...resource.image.gatsbyImageData
                              }
                            }
                          }}
                        />
                      </div>
                      <div>
                        <h3>{resource.title}</h3>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: descriptionContent
                          }}
                        ></div>
                        <div className={styles.button}>
                          <Button
                            theme="arrow-without-padding"
                            to={`/${this.pageContext.permalink}/${resource.permalink}`}
                          >
                            Read More
                          </Button>
                        </div>
                      </div>
                    </div>
                  )
                })}
            </div>

            {this.pageContext.numPages > 1 && (
              <div className={styles.pagination}>
                <Link
                  to={
                    this.pageContext.currentPage === 2
                      ? `/${this.pageContext.permalink}`
                      : `/${this.pageContext.permalink}/${this.pageContext.currentPage - 1}`
                  }
                  className={leftPaginationClasses}
                >
                  <SVG name="angle-left" />
                </Link>

                {paginationArray.map((item, index) => {
                  const classes = classNames(styles.link, {
                    [styles.active]: item.page === this.pageContext.currentPage
                  })

                  return (
                    <Link to={item.link} key={index} className={classes}>
                      {item.page}
                    </Link>
                  )
                })}

                <Link
                  to={`/${this.pageContext.permalink}/${this.pageContext.currentPage + 1}`}
                  className={rightPaginationClasses}
                >
                  <SVG name="angle-right" />
                </Link>
              </div>
            )}
          </div>
        </Layout>
      </>
    )
  }
}

export default ResourceCenterPage

export const query = graphql`
  query ResourceTruckVanQuery($skip: Int = 0, $limit: Int = 10) {
    ...MegaMenu
    ...Footer
    datoCmsResourcesTruckVan {
      body
      seoMetaTags {
        tags
      }
      bodyNode {
        childrenMarkdownRemark {
          html
        }
      }
      title
      permalink
    }
    allDatoCmsResourceTruckVan(
      limit: $limit
      skip: $skip
      sort: { fields: meta___publishedAt, order: DESC }
    ) {
      nodes {
        title
        permalink
        description
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
        image {
          gatsbyImageData
        }
      }
      totalCount
    }
  }
`
